const backToTop = document.querySelector('#back-to-top');
const menuPiedItems = document.querySelectorAll(
  '#menu-pied-de-page > .menu-item > a'
);

menuPiedItems.forEach((item) => {
  const itemText = item.textContent;
  item.innerHTML = `<span>${itemText}</span><span class="hidden">${itemText}</span>`;
});

backToTop.addEventListener('click', () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
});
